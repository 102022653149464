import { userApi } from '../UserApi'
import { AddUserPayload } from '../../Type/ApiTypes'

const adminToolsApi = userApi.injectEndpoints({
  endpoints: (builder: any) => ({
    addUser: builder.mutation({
      query: ({
        payload,
        customerOwnerId,
      }: {
        payload: AddUserPayload
        customerOwnerId: number
      }) => ({
        url: 'api/v1/user/',
        method: 'POST',
        body: payload,
        params: { customerOwnerId },
      }),
    }),

    getUsers: builder.mutation({
      query: ({
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        customerOwnerId,
        searchText,
      }: {
        pageNumber?: number
        pageSize?: number
        sortBy?: string
        sortDir?: string
        customerOwnerId?: number
        searchText?: string
      }) => ({
        url: 'api/v1/user/',
        method: 'GET',
        params: { pageNumber, pageSize, sortBy, sortDir, customerOwnerId, searchText },
      }),
    }),

    getQuickBook: builder.mutation({
      query: ({}) => ({
        url: 'api/v1/QBOCustomer/',
        method: 'GET',
      }),
    }),

    deleteUser: builder.mutation({
      query: ({ userId, customerOwnerId }: { userId?: number; customerOwnerId?: number }) => ({
        url: `api/v1/user/${userId}`,
        method: 'DELETE',
        params: { customerOwnerId },
      }),
    }),

    updateUser: builder.mutation({
      query: ({
        payload,
        id,
        customerOwnerId,
      }: {
        payload: AddUserPayload
        id: number
        customerOwnerId?: number
      }) => ({
        url: `api/v1/user/${id}`,
        method: 'PUT',
        body: payload,
        params: { customerOwnerId },
      }),
    }),

    mapCustomerToQuickBook: builder.mutation({
      query: ({
        quickbookCustomerId,
        customerId,
      }: {
        quickbookCustomerId: number
        customerId: number
      }) => ({
        url: `api/v1/quickbook/saveMappingCustomerToQuickbook/${quickbookCustomerId}/${customerId}`,
        method: 'POST',
      }),
    }),

    editMapCustomerToQuickBook: builder.mutation({
      query: ({
        quickbookCustomerId,
        customerId,
      }: {
        quickbookCustomerId: number
        customerId: number
      }) => ({
        url: `api/v1/quickbook/editMappingCustomerToQuickbook/${quickbookCustomerId}/${customerId}`,
        method: 'PUT',
      }),
    }),

    // ✅ New Mutation for Saving Email Settings
    saveEmailSetting: builder.mutation({
      query: (payload: {
        customerId: number
        mooringNumber: string
        enableEmails: boolean
        replyEmailAddress: string
        overrideCustomerEmail: boolean
        OverrideEmailAddress: string
        remindertimeinDays: number
      }) => ({
        url: 'api/v1/Settings/',
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),


    saveCompanyInformation: builder.mutation({
      query: (payload: {
        customerId: number
        companyname: string
        companyaddress: string
        companyemailAddress: string
        companylogo: string
      }) => ({
        url: 'api/v1/Settings/companyinformation', // Adjust endpoint based on your backend API
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),


    saveMooringInformation: builder.mutation({
      query: (payload: {
        inspectionOccurrenceTime: string;
      }) => ({
        url: 'api/v1/Settings/mooringinformation',
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),


    SaveBillingInformation: builder.mutation({
      query: (payload: {
        inspectionOccurrenceTime: string;
      }) => ({
        url: 'api/v1/Settings/billinginformation',
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),


    getEmailSettings: builder.query({
      query: (customerOwnerId: number) => ({
        url: 'api/v1/Settings/FeatchEmailsettings',
        method: 'GET',
        params: { customerOwnerId },
      }),
    }),


    getCompanyInformation: builder.query({
      query: (customerOwnerId: number) => ({
        url: 'api/v1/Settings/FeatchCompanyInformation',
        method: 'GET',
        params: { customerOwnerId },
      }),
    }),

    getMooringInformation: builder.query({
      query: (customerOwnerId: number) => ({
        url: 'api/v1/Settings/FeatchMooringInformation',
        method: 'GET',
        params: { customerOwnerId },
      }),
    }),


      // ✅ Update Email Settings - Changed method to PUT
      UpdateEmailSetting: builder.mutation({
        query: (payload: {
          ID: number;
          customerId: number;
          enableEmails: boolean;
          replyEmailAddress: string;
          overrideCustomerEmail: boolean;
          overrideEmailAddress: string;
          remindertimeinDays: number;
        }) => ({
          url: `api/v1/Settings/${payload.ID}`,
          method: 'PUT',
          body: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      }),


        // ✅ Update Email Settings - Changed method to PUT
        UpdateCompanyInformation: builder.mutation({
          query: (payload: {
            ID: number;
            customerId: number;
            companyname: string;
            companyaddress: string;
            companyemailAddress: string;
            companylogo: string;
          }) => ({
            url: `api/v1/Settings/companyinformation/${payload.ID}`,
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        }),


    

        // ✅ Update Email Settings - Changed method to PUT
        UpdateMooringInformation: builder.mutation({
          query: (payload: {
            ID: number;
            customerId: number;
            inspectionoccurrencetime: number;

          }) => ({
            url: `api/v1/Settings/mooringinformation/${payload.ID}`,
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        }),


        getBillingInformation: builder.query({
          query: (customerOwnerId: number) => ({
            url: 'api/v1/Settings/FeatchBillingInformation',
            method: 'GET',
            params: { customerOwnerId },
          }),
        }),


        // ✅ Update Email Settings - Changed method to PUT
        UpdateBillingInformation: builder.mutation({
          query: (payload: {
            id: number;
            xero: Boolean;
            quickbooks: Boolean;
            customerOwnerId:number;

          }) => ({
            url: `api/v1/Settings/billinginformation/${payload.id}`,
            method: 'PUT',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        }),


        getXeroAccUserInfo: builder.query({
          query: (customerOwnerId: number) => ({
            url: 'api/v1/workOrder/FeatchXerouserAccountInfo',
            method: 'GET',
            params: { customerOwnerId },
          }),
        }),


        getQuickbookAccUserInfo: builder.query({
          query: (customerOwnerId: number) => ({
            url: 'api/v1/workOrder/FeatchQuickbookAccountInfo',
            method: 'GET',
            params: { customerOwnerId },
          }),
        }),

    
  }),
})




export const {
  useAddUserMutation,
  useGetUsersMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useMapCustomerToQuickBookMutation,
  useEditMapCustomerToQuickBookMutation,
  useGetQuickBookMutation,
  // ✅ Export the new mutation hook
  useSaveEmailSettingMutation,
  useSaveCompanyInformationMutation,
  useSaveMooringInformationMutation,
  useLazyGetEmailSettingsQuery, // Export the new query
  useLazyGetCompanyInformationQuery,
  useLazyGetMooringInformationQuery,
  useUpdateEmailSettingMutation,
  useUpdateCompanyInformationMutation, 
  useUpdateMooringInformationMutation,
  useSaveBillingInformationMutation,
  useLazyGetBillingInformationQuery,
  useUpdateBillingInformationMutation,
  useLazyGetXeroAccUserInfoQuery,
  useLazyGetQuickbookAccUserInfoQuery,
} = adminToolsApi
