import React, { useState, useEffect  } from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { ApproveModalProps } from '../../../Type/ComponentBasedType'
import InputComponent from '../../CommonComponent/InputComponent'
import { Params } from '../../../Type/CommonType'
import { useApproveWorkOrderMutation } from '../../../Services/MoorServe/MoorserveApi'
import { ErrorResponse, WorkOrderResponse } from '../../../Type/ApiTypes'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { useLazyGetBillingInformationQuery } from '../../../Services/AdminTools/AdminToolsApi';
import { useLazyGetXeroAccUserInfoQuery } from '../../../Services/AdminTools/AdminToolsApi';
import { useLazyGetQuickbookAccUserInfoQuery } from '../../../Services/AdminTools/AdminToolsApi';





const ApproveModal: React.FC<ApproveModalProps> = ({
  id,
  toast,
  setVisible,
  closeModal,
  amountValue,
  getWorkOrderWithPendingPayApproval,
  getOutStandingInvoice,
}) => {
  const [invoiceAmount, setInvoiceAmount] = useState<any>(amountValue)
  const [errorMessage, setErrorMessage] = useState<{ [key: string]: string }>({})
  const [approveWorkOrder] = useApproveWorkOrderMutation()
  const [codeNotFound, setCodeNotFound] = useState(false);
  const [xeroLoginWindow, setXeroLoginWindow] = useState<any>(null); // Store reference to the popup window
  const [getBillingInformation] = useLazyGetBillingInformationQuery();
  const [getXeroAccUserInfo] = useLazyGetXeroAccUserInfoQuery();
  const [getQuickbookAccUserInfo] = useLazyGetQuickbookAccUserInfoQuery();
  const [GetXeroBillingInfo, setXeroBillingInfo] = useState<boolean>(false);
  const [GetQuickbookBillingInfo, setQuickbookBillingInfo] = useState<boolean>(false);
  const [GetXerouserInfo, setXerouserInfo] = useState<string>("");
  const [GetQuickbookuserInfo, setQuickbookuserInfo] = useState<string>("");
  //const [GetBillingidforupdate, setBillingidforupdate] = useState(0)

  const validateFields = () => {
    const errors: { [key: string]: string } = {}

    if (!invoiceAmount) {
      errors.invoiceAmount = 'Invoice Amount is required'
    }
    setErrorMessage(errors)
    return Object.keys(errors).length === 0
  }

   // **Fetching the query parameter `code`**
   const [searchParams] = useSearchParams();
   console.log(searchParams);  // Log all parameters to ensure 'code' is present
   const code = searchParams.get('code');
   console.log("Authorization code: ", code);  // Log the value of 'code'


   interface BillingInformationResponce {
    content: {
      id:number;
      xero:Boolean;
      quickbooks : Boolean;
    }[];
  }


  interface XeroUserAccountInfoResponce {
    content: {
      id:number;
      authorizationCode:string;
      refreshToken:string;
      accessToken:string;
      lastUpdatedTime: string; 
      loginUserId: number; 
    }[];
  }

  interface QuickbookUserAccountInfoResponce {
    content: {
      id:number;
      authorizationCode:string;
      refreshToken:string;
      accessToken:string;
      lastUpdatedTime: string; 
      loginUserId: number; 
    }[];
  }



   useEffect(() => {
    const determineLoginProvider = async () => {
      try {
        //const response = await axios.get('/api/login-provider') // Replace with your actual API
        //const provider = response.data.provider
        debugger;
        const customerOwnerId = 2; 
        const billingResponse = (await getBillingInformation(customerOwnerId).unwrap()) as BillingInformationResponce;
        const Billinginfo = billingResponse?.content?.[0] || {};
 
        setXeroBillingInfo(!!Billinginfo?.xero); // ensures it's a primitive boolean
        setQuickbookBillingInfo(!!Billinginfo?.quickbooks)


        const QuickookAccResponse = (await getQuickbookAccUserInfo(customerOwnerId).unwrap()) as QuickbookUserAccountInfoResponce;
        const QuickbookUserInfo = QuickookAccResponse?.content?.[0] || {};
        debugger;

        setQuickbookuserInfo(QuickbookUserInfo?.refreshToken || "");

        const XeroAccResponse = (await getXeroAccUserInfo(customerOwnerId).unwrap()) as XeroUserAccountInfoResponce;
        const XeroUserInfo = XeroAccResponse?.content?.[0] || {};
        debugger;
        

        setXerouserInfo(XeroUserInfo?.refreshToken || "");

        //let timeDifferenceInHours = 0;
        // if (XeroUserInfo?.lastUpdatedTime) {
        //   debugger;
        //   approveWorkOrderAfterLogin(access_tokens);
          
        // } else {

        //   console.log(" No lastUpdatedTime found, triggering login...");

        //   if (Billinginfo?.xero === true) {
        //     ApproveWorkOrderMethod(); // open Xero login
        //   } else if (Billinginfo?.quickbooks === true) {
        //     console.log("QuickBooks flow (not implemented yet)");
        //   } else {
        //     console.warn("No billing provider configured.");
        //   }
          
        // }
        

        // debugger;
        // if (Billinginfo?.xero === true) {
        //   debugger;
        //   //setSelectedOption("Xero");
        // } else if (Billinginfo?.quickbooks === true) {
        //   debugger;
        //   //setSelectedOption("QuickBooks");
        // } else {
        //   debugger;
        //   //setSelectedOption(""); // No selection if both are false
        // }
      } catch (error) {
        console.error('Failed to determine login provider:', error)
      }
    }
  
    determineLoginProvider()
  }, [])
  

  

  // useEffect(() => {
  //   const timer = setInterval(() => {
      
      

  //     if (xeroLoginWindow && xeroLoginWindow.location) {

  //       let xeroHref = null;
  //       debugger;
  //       try {
  //         if (xeroLoginWindow.location && xeroLoginWindow.location.href) {
  //           xeroHref = xeroLoginWindow.location.href;
  //         }
  //       } catch (error) {
  //         console.warn("Cross-origin access blocked:", error);
  //       }
        
  //       if (xeroHref) {
  //         console.log("Xero Login Window URL:", xeroHref);
  //         const currentUrl = xeroHref;
  //         const codeFromUrl = new URL(currentUrl).searchParams.get('code');

  //         debugger;

  //         if (codeFromUrl) {
  //           console.log('Code found in popup URL:', codeFromUrl);
  //           // handleXeroCallback(codeFromUrl);
  //           approveWorkOrderAfterLogin(codeFromUrl);
  //           clearInterval(timer); // Clear the interval once we find the code
  //         }
  //       } else {
  //         console.log("Cannot access xeroLoginWindow.location.href due to cross-origin restrictions.");
  //       }

  //     } else {
  //       console.log("Xero login window not found or still loading.");
  //     }
  //   }, 1000); // Check every second for the updated URL

  //   return () => clearInterval(timer); // Clean up interval when the component unmounts
  // }, [xeroLoginWindow]); // Effect runs only when the xeroLoginWindow reference changes


   useEffect(() => {
     const timer = setInterval(() => {
      
      if (xeroLoginWindow && xeroLoginWindow.location) {

        let xeroHref = null;
        debugger;
        try {
          if (xeroLoginWindow.location && xeroLoginWindow.location.href) {
            xeroHref = xeroLoginWindow.location.href;
          }
        } catch (error) {
          console.warn("Cross-origin access blocked:", error);
        }
        
        if (xeroHref) {
          console.log("Xero Login Window URL:", xeroHref);
          const currentUrl = xeroHref;
          const codeFromUrl = new URL(currentUrl).searchParams.get('code');

          debugger;

          if (codeFromUrl) {
            console.log('Code found in popup URL:', codeFromUrl);
            // handleXeroCallback(codeFromUrl);
            approveWorkOrderAfterLogin(codeFromUrl);
            clearInterval(timer); // Clear the interval once we find the code
          }
        } else {
          console.log("Cannot access xeroLoginWindow.location.href due to cross-origin restrictions.");
        }

      } else {
        console.log("Xero login window not found or still loading.");
      }
    }, 1000); // Check every second for the updated URL

    return () => clearInterval(timer); // Clean up interval when the component unmounts
  }, [xeroLoginWindow]); // Effect runs only when the xeroLoginWindow reference changes



  // useEffect(() => {
  //   const handleMessage = (event: MessageEvent) => {
  //     // ✅ Only accept messages from your own domain
  //     if (event.origin !== window.location.origin) {
  //       console.warn("Ignored message from unknown origin:", event.origin);
  //       return;
  //     }
  
  //     const { code } = event.data;
  //     if (code) {
  //       console.log("Authorization code received from popup:", code);
  //       approveWorkOrderAfterLogin(code); // ✅ Proceed with your logic
  //     }
  //   };
  
  //   // ✅ Add message listener
  //   window.addEventListener("message", handleMessage);
  
  //   return () => {
  //     // ✅ Clean up the listener on component unmount
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);
  


  const handleInvoiceAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9.]/g, '')
    setInvoiceAmount(value)
    setErrorMessage({})
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key
    if (!/^[0-9]$/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== '.') {
      e.preventDefault()
    }
  }

  const handleBack = () => {
    setVisible(false)
  }

          const ApproveWorkOrderMethod = async () => {
            if (!validateFields()) return;
            const access_tokens = "";
           
            const quickbook = GetQuickbookuserInfo;
            debugger;


            if (GetXeroBillingInfo) {
              if (GetXerouserInfo) {
                debugger;
                approveWorkOrderAfterLogin(access_tokens);
              } else {
                XeroAccountInfo();
              }
            }

            if (GetQuickbookBillingInfo) {
              if (GetQuickbookuserInfo) {
                debugger;
                approveWorkOrderAfterLogin(access_tokens);
              } else {
                QuickbookAccountInfo();
              }
            }


              // if (GetXerouserInfo) {
              //   debugger;
              //   approveWorkOrderAfterLogin(access_tokens);
              // } else {
              //   if (GetXeroBillingInfo) {
              //     debugger;
              //     XeroAccountInfo();
              //   } else if (GetQuickbookBillingInfo) {
              //     console.log("QuickBooks logic here");
              //   }
              // }
          }


          const XeroAccountInfo = () => {
            try {
              debugger;
              const clientId = '634D880CD7414C6586257D1C298F50AD';  // Xero client ID
              const redirectUri = 'https://dev.moorfind.com/callback'; // Your redirect URI
              const scope = 'openid profile email accounting.transactions accounting.contacts offline_access&state=123';
              const responseType = 'code';
          
              const authorizationUrl = `https://login.xero.com/identity/connect/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
          
              const xeroLoginWindow = window.open(authorizationUrl, "_blank", "width=600,height=600");
              setXeroLoginWindow(xeroLoginWindow);
          
              if (!xeroLoginWindow) {
                console.error('Failed to open the Xero login window. Make sure pop-ups are not blocked.');
                return;
              }
          
            } catch (error) {
              const { message: msg } = error as ErrorResponse;
              console.error('Error occurred while opening Xero login window:', msg);
            }
          };
          

          const QuickbookAccountInfo = () => {
            try {
              debugger;
              const clientId = "ABISe8r2ZTOmbRTA0Djw7wmAA5SumxfT3ndgbLbFsCENTYnc0K";
              const redirectUri = "https://dev.moorfind.com/callback";
              const scope = "com.intuit.quickbooks.accounting com.intuit.quickbooks.payment";
              const responseType = "code";
              const state = "123456";
          
              const authorizationUrl = `https://appcenter.intuit.com/connect/oauth2?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(
                redirectUri
              )}&scope=${encodeURIComponent(scope)}&state=${state}`;
          
              const quickbookLoginWindow = window.open(authorizationUrl, "_blank", "width=600,height=600");
              setXeroLoginWindow(quickbookLoginWindow);


              if (!quickbookLoginWindow) {
                console.error("Failed to open the QuickBooks login window. Make sure pop-ups are not blocked.");
                return;
              }
          
              // Optionally store window reference
              // setQuickbookLoginWindow(quickbookLoginWindow);
          
            } catch (error) {
              const { message: msg } = error as ErrorResponse;
              console.error("Error occurred while opening QuickBooks login window:", msg);
            }
          };
          


        // 3. Handle the Xero Callback (in the callback route component)
        const handleXeroCallback = async (authorizationCode: string) => {
          try {
            debugger;
            // Exchange authorization code for an access token
            const tokenResponse = await axios.post(
              'https://identity.xero.com/connect/token',
              {
                grant_type: 'authorization_code',
                code: authorizationCode,
                redirect_uri: 'http://localhost:3000/callback',  // Same as the one used for login
                client_id: '634D880CD7414C6586257D1C298F50AD',
                client_secret: 'vZW1Cu4MSN3qDBJHqSDqzrNIjQPaXYtjX7LSLYac',  // Replace with your Xero client secret
              },
              {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              }
            );
            debugger;
            const { access_token, refresh_token } = tokenResponse.data;
            debugger;
            if (access_token) {
              // Once we have the access token, proceed with the work order approval
              await approveWorkOrderAfterLogin(access_token);
            } else {
              console.error('Access token is null. Check the response from Xero API.');
            }
          } catch (error) {
            console.error("Error exchanging code for token", error);
          }
        };


        const approveWorkOrderAfterLogin = async (accessToken: string) => {
          try {
            debugger;
            // 4. After successful login, proceed with work order approval
            const params: Params = {}
            if (invoiceAmount) {
              params.invoiceAmount = invoiceAmount
            }
            const response = await approveWorkOrder({ id: id, invoiceAmount: invoiceAmount, accessToken: accessToken }).unwrap()
            const { status, message } = response as WorkOrderResponse
            if (status === 200) {
              closeModal()
              getWorkOrderWithPendingPayApproval()
              getOutStandingInvoice()
              toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: message,
                life: 3000,
              })
            } else {
              closeModal()
              getWorkOrderWithPendingPayApproval()
              getOutStandingInvoice()
              toast?.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: message,
                life: 3000,
              })
            }
          } catch (error) {
            const { message: msg } = error as ErrorResponse
            console.error('Error occurred while approving work order:', msg)
          }
        }


  return (
    <>
      <div>
        <Toast ref={toast} />

        <div className=" mt-4">
          <span className="font-medium text-sm text-[#000000]">
            <div className="flex gap-2 ml-2">
              Invoice Amount
              <p className="text-red-600">*</p>
            </div>
          </span>
          <div className="mt-1 ml-1 text-[#000000]">
            <div className="">
              <InputComponent
                type="number"
                value={invoiceAmount}
                onKeyDown={handleKeyDown}
                onChange={handleInvoiceAmountChange}
                disabled={true}
                style={{
                  width: '450px',
                  height: '40px',
                  border: errorMessage.invoiceAmount ? '1px solid red' : '1px solid #D5E1EA',
                  borderRadius: '0.50rem',
                  boxShadow: 'none',
                  paddingLeft: '0.5rem',
                  fontSize: '0.8rem',
                }}
              />
            </div>
          </div>
          <p>
            {errorMessage.invoiceAmount && (
              <small className="p-error">{errorMessage.invoiceAmount}</small>
            )}
          </p>
        </div>
      </div>
      {/* Save and Back buttons */}
      <div
        className={`"flex  absolute "`}
        style={{
          width: '100%',
          height: '65px',
          backgroundColor: 'white',
          padding: '0 12px',
          bottom: '0px',
        }}>
        <Button
          label={'Save'}
          onClick={ApproveWorkOrderMethod}
          style={{
            width: '89px',
            height: '42px',
            backgroundColor: '#0098FF',
            cursor: 'pointer',
            fontWeight: 'bolder',
            fontSize: '1rem',
            boxShadow: 'none',
            color: 'white',
            borderRadius: '0.50rem',
            marginTop: '15px',
          }}
        />
        <Button
          onClick={handleBack}
          label={'Back'}
          text={true}
          style={{
            backgroundColor: 'white',
            color: '#000000',
            border: 'none',
            width: '89px',
            height: '42px',
            marginTop: '15px',
          }}
        />
      </div>
    </>
  )
}

export default ApproveModal
