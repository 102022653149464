import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataTableComponent from '../CommonComponent/Table/DataTableComponent'
import Header from '../Layout/LayoutComponents/Header'
import { useSelector } from 'react-redux'
import { CustomerPayload, CustomerResponse, ErrorResponse } from '../../Type/ApiTypes'
import { Toast } from 'primereact/toast'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Paginator } from 'primereact/paginator'
import { DropdownCellProps, Params, State } from '../../Type/CommonType'
import { properties } from '../Utils/MeassageProperties'
import { Dropdown } from 'primereact/dropdown'
import { QuickBooksCustomerData } from '../CommonComponent/MetaDataComponent/MetaDataApi'
import { useGetCustomerMutation } from '../../Services/MoorManage/MoormanageApi'
import { selectCustomerId } from '../../Store/Slice/userSlice'
import type { AddEmailSetting } from '../../Type/ComponentBasedType'
import { useSaveEmailSettingMutation } from '../../Services/AdminTools/AdminToolsApi'
import { useSaveCompanyInformationMutation } from '../../Services/AdminTools/AdminToolsApi'
import { useSaveMooringInformationMutation } from '../../Services/AdminTools/AdminToolsApi'
import { useSaveBillingInformationMutation } from '../../Services/AdminTools/AdminToolsApi'
import { useLazyGetEmailSettingsQuery } from '../../Services/AdminTools/AdminToolsApi';
import { useLazyGetCompanyInformationQuery } from '../../Services/AdminTools/AdminToolsApi';
import { useLazyGetMooringInformationQuery } from '../../Services/AdminTools/AdminToolsApi';
import { useLazyGetBillingInformationQuery } from '../../Services/AdminTools/AdminToolsApi';
import { useUpdateEmailSettingMutation } from '../../Services/AdminTools/AdminToolsApi';
import { useUpdateCompanyInformationMutation } from '../../Services/AdminTools/AdminToolsApi';
import { useUpdateMooringInformationMutation } from '../../Services/AdminTools/AdminToolsApi';
import { useUpdateBillingInformationMutation } from '../../Services/AdminTools/AdminToolsApi';
import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button'
import {
  useEditMapCustomerToQuickBookMutation,
  useMapCustomerToQuickBookMutation,
} from '../../Services/AdminTools/AdminToolsApi'
import { CustomerfirstLastName } from '../Helper/Helper'


interface FormDataType {
  customerName: string;
  mooringNumber: string;
  enableEmails: string;
  replyEmailAddress: string;
  overrideCustomerEmail: string;
  overrideEmailAddress: string;
  remindertimeinDays: string;
  enableEmailsfromtosend: boolean;
  overrideCustomerEmails: boolean; // ✅ This is already correct
  
  // Below is for company info
  companyname : string;
  companyaddress : string;
  companyemailAddress : string;
  companylogo : string; 
  emailError : string;


  //Below is for Mooring Information
  inspectionoccurrencetime :string;

  //below  is for the Billing information.
  xero: string;
  quickbook: string;

}

interface EmailSettingsResponse {
  // replyToEmailAddress: string;
  // overrideCustomerEmail: boolean;
  // overrideEmailAddress: string;
  // reminderTimeInDays: number;
  // enableEmails: boolean;

  content: {
    id:number;
    replyToEmailAddress: string;
    overrideCustomerEmail: boolean;
    overrideEmailAddress: string;
    reminderTimeInDays: number;
    enableEmails: boolean;
  }[];

}

interface CompnayInformationResponce {
  content: {
    id:number;
    companyName : string;
    companyAddress : string;
  companyEmailAddress : string;
  companyLogo : string; 
  }[];

}

interface MooringInformationResponce {
  content: {
    id:number;
    inspectionOccurrenceTime : number;
  }[];
}

interface BillingInformationResponce {
  content: {
    id:number;
    xero:Boolean;
    quickbooks : Boolean;
  }[];
}



const Settings: React.FC<AddEmailSetting> = ({
  getCustomer,
  toastRef,
}) => {
  const [SaveEmailSetting] = useSaveEmailSettingMutation(); // Mutation hook
  const [SaveCompanyInformation] = useSaveCompanyInformationMutation();
  const [SaveMooringInformation] = useSaveMooringInformationMutation();
  const [SaveBillingInformation] = useSaveBillingInformationMutation();
  const [getEmailSettings] = useLazyGetEmailSettingsQuery();
  const [getCompanyInformation] = useLazyGetCompanyInformationQuery();
  const [getMooringInformation] = useLazyGetMooringInformationQuery();
  const [getBillingInformation] = useLazyGetBillingInformationQuery();
  const [UpdateEmailSetting] = useUpdateEmailSettingMutation(); // Mutation hook
  const [UpdateCompanyInformation] = useUpdateCompanyInformationMutation(); // Mutation hook
  const [UpdateMooringInformation] = useUpdateMooringInformationMutation(); // Mutation hook
  const [UpdateBillingInformation] = useUpdateBillingInformationMutation(); // Mutation hook

  const [isFetching, setIsFetching] = useState(false);
  
  const [EmailSettingsEdit, setEmailSettingsEdit] = useState(false); // Local variable
  const [Getidforupdate, setGetidforupdate] = useState(0)
  const [GetCompanyidforupdate, setCompanyidforupdate] = useState(0)
  const [GetMooringidforupdate, setMooringidforupdate] = useState(0)
  const [GetBillingidforupdate, setBillingidforupdate] = useState(0)
  const [CompanyInformationEdit, setCompanyInformationEdit] = useState(false); // Local variable
  const [MooringInformationEdit, setMooringINformationEdit] = useState(false); // Local variable
  const [BillingInformationEdit, setBillingInformationEdit] = useState(false); // Local variable

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); 

  const [fetchCustomerData] = useGetCustomerMutation();
  const selectedCustomerId = useSelector(selectCustomerId)
  const [quickBookCustomer, setQuickBookCustomer] = useState<{ label: any; id: any }[]>([])

  // const [getCustomer] = useGetCustomerMutation()
  const toast = useRef<Toast>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [totalRecords, setTotalRecords] = useState<number>()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageNumber1, setPageNumber1] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [currentlyEditing, setCurrentlyEditing] = useState<any>(null)
  const [dropdownValues, setDropdownValues] = useState<{ [key: string]: string }>({})
  const [savedValues, setSavedValues] = useState<{ [key: string]: string }>({})
  const [customerData, setCustomerData] = useState<CustomerPayload[]>([])
  const [dropdownDisabled, setDropdownDisabled] = useState<{ [key: string]: boolean }>({})
  const { getQuickBookCustomerData } = QuickBooksCustomerData()
  const [mapCustomerToQuickBook] = useMapCustomerToQuickBookMutation()
  const [editMapCustomerToQuickBook] = useEditMapCustomerToQuickBookMutation()
  const [accordion, setAccordion] = useState('faq1')

  //  Add state for the selected billing option
  const [selectedOption, setSelectedOption] = useState<string>(""); // Default empty

  //  Define the function to handle selection
  const handleSelection = (option: string) => {
    setSelectedOption(option); // Update the selected billing option
    setFormData((prevState) => ({
      ...prevState,
      xero: option === "Xero" ? "Xero" : "",
      quickbook: option === "QuickBooks" ? "QuickBooks" : "",
    }));
  };
  

  // ✅ Corrected formData state type
  const [formData, setFormData] = useState<FormDataType>({
    customerName: '',
    mooringNumber: '',
    enableEmails: '',
    replyEmailAddress: '',
    overrideCustomerEmail: '',
    overrideEmailAddress: '',
    remindertimeinDays: '',
    enableEmailsfromtosend: false,
    overrideCustomerEmails: false,
   // Company name
     companyname:'',
     companyaddress:'',
     companyemailAddress:'',
     companylogo:'',
  // Mooring Info 
     inspectionoccurrencetime:'',
     emailError:'',
    //Billing info
    xero:'',
    quickbook:'',




  });

  


    // ✅ Updated handleInputChange function
  const handleInputChange = (key: keyof FormDataType, value: string | boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

    const saveEmailSettingsHandler = async () => {
      try {
        setIsLoading(true)
        const payload = {
          customerId: formData?.customerName,
          mooringNumber: formData?.mooringNumber,
          enableEmails: formData?.enableEmailsfromtosend,
          replyEmailAddress: formData.replyEmailAddress,
          overrideCustomerEmail: formData?.overrideCustomerEmails,
          overrideEmailAddress: formData?.overrideEmailAddress,
          remindertimeinDays: formData?.remindertimeinDays,
        }
  
        const response = await SaveEmailSetting(payload).unwrap()
        const { status, message } = response as CustomerResponse
        if (status === 200 || status === 201) {
          setSuccessMessage(message);  // ✅ Set success message
          setShowSuccessPopup(true);   // ✅ Show popup
          setIsLoading(false);
    
          // ✅ Auto-close after 3 seconds
          setTimeout(() => setShowSuccessPopup(false), 3000);
        } else {
          setIsLoading(false)
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          })
        }// } else {
        //   toastRef?.current?.show({
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: message,
        //     life: 3000,
        //   })
        //   setIsLoading(false)
        // }
      } catch (error) {
        const { message, data } = error as ErrorResponse
        setIsLoading(false)
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        })
      }
    }

    const updateEmailSettingsHandler = async () => {
      try {
        setIsLoading(true)
        const payload = {
          ID : Getidforupdate,
          customerId: Getidforupdate,
         // mooringNumber: formData?.mooringNumber,
          enableEmails: formData?.enableEmailsfromtosend,
          replyEmailAddress: formData.replyEmailAddress,
          overrideCustomerEmail: formData?.overrideCustomerEmails,
          overrideEmailAddress: formData?.overrideEmailAddress,
          remindertimeinDays: formData?.remindertimeinDays,
        }
  
        const response = await UpdateEmailSetting(payload).unwrap()
        const { status, message } = response as CustomerResponse
        if (status === 200 || status === 201) 
          {
            setSuccessMessage(message);  // ✅ Set success message
            setShowSuccessPopup(true);   // ✅ Show popup
            setIsLoading(false);
      
            // ✅ Auto-close after 3 seconds
            setTimeout(() => setShowSuccessPopup(false), 3000);
          } else
          {
          setIsLoading(false)
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          })
        } // } else {
        //   toastRef?.current?.show({
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: message,
        //     life: 3000,
        //   })
        //   setIsLoading(false)
        // }
      } catch (error) {
        const { message, data } = error as ErrorResponse
        setIsLoading(false)
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        })
      }
    }



    const updateCompanyInformationHandler = async () => {
      try {
        setIsLoading(true)
        const payload = {
          ID : GetCompanyidforupdate,
          customerId: GetCompanyidforupdate,
          companyname: formData?.companyname,
          companyaddress:formData?.companyaddress,
          companyemailAddress:formData?.companyemailAddress,
          companylogo: formData?.companylogo,
        }
  
        const response = await UpdateCompanyInformation(payload).unwrap()
        const { status, message } = response as CustomerResponse
        if (status === 200 || status === 201) {
          setSuccessMessage(message);  // ✅ Set success message
          setShowSuccessPopup(true);   // ✅ Show popup
          setIsLoading(false);
    
          // ✅ Auto-close after 3 seconds
          setTimeout(() => setShowSuccessPopup(false), 3000);
        } else {
          setIsLoading(false)
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          })
        } // } else {
        //   toastRef?.current?.show({
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: message,
        //     life: 3000,
        //   })
        //   setIsLoading(false)
        // }
      } catch (error) {
        const { message, data } = error as ErrorResponse
        setIsLoading(false)
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        })
      }
    }



  
    const saveCompanyInformationHandler = async () => {
      try {
        setIsLoading(true)
        const payload = {
          customerId: formData?.customerName,
          companyname: formData?.companyname,
          companyaddress:formData?.companyaddress,
          companyemailAddress:formData?.companyemailAddress,
          companylogo: formData?.companylogo,
        }
  
        const response = await SaveCompanyInformation(payload).unwrap()
        const { status, message } = response as CustomerResponse
        if (status === 200 || status === 201) {
            setSuccessMessage(message);  // ✅ Set success message
            setShowSuccessPopup(true);   // ✅ Show popup
            setIsLoading(false);
      
            // ✅ Auto-close after 3 seconds
            setTimeout(() => setShowSuccessPopup(false), 3000);
          } else
          {
          setIsLoading(false)
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          })
         } // } else {
        //   toastRef?.current?.show({
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: message,
        //     life: 3000,
        //   })
        //   setIsLoading(false)
        // }
      } catch (error) {
        const { message, data } = error as ErrorResponse
        setIsLoading(false)
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        })
      }
    }


    const updateMooringInformationHandler = async () => {
      try {
        setIsLoading(true)
        const payload = {
          ID : GetMooringidforupdate,
          customerId: GetMooringidforupdate,
         inspectionoccurrencetime: formData?.inspectionoccurrencetime,
        }
  
        const response = await UpdateMooringInformation(payload).unwrap()
        const { status, message } = response as CustomerResponse
        if (status === 200 || status === 201) {
          setSuccessMessage(message);  // ✅ Set success message
          setShowSuccessPopup(true);   // ✅ Show popup
          setIsLoading(false);
    
          // ✅ Auto-close after 3 seconds
          setTimeout(() => setShowSuccessPopup(false), 3000);
        } else {
          setIsLoading(false)
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          })
        }
        // } else  {
        //   toastRef?.current?.show({
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: message,
        //     life: 3000,
        //   })
        //   setIsLoading(false)
        // }
      } catch (error) {
        const { message, data } = error as ErrorResponse
        setIsLoading(false)
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        })
      }
    }

    
    const updateBillingInformationHandler = async () => {
      try {
        setIsLoading(true);
    
        // Ensure selectedOption is processed before forming the payload
        handleSelection(selectedOption);
    
        const payload = {
          id: GetBillingidforupdate, // Corrected to use the correct billing ID
          customerOwnerId: GetBillingidforupdate, // Ensure this corresponds correctly
          xero: selectedOption === "Xero" ? "true" : "false",
          quickbooks: selectedOption === "QuickBooks" ? "true" : "false",
        };
    
        const response = await UpdateBillingInformation(payload).unwrap();
        const { status, message } = response as CustomerResponse;
    
        if (status === 200 || status === 201) {
          setSuccessMessage(message); // ✅ Set success message
          setShowSuccessPopup(true); // ✅ Show popup
          setIsLoading(false);
    
          // ✅ Auto-close after 3 seconds
          setTimeout(() => setShowSuccessPopup(false), 3000);
        } else {
          setIsLoading(false);
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          });
        }
      } catch (error) {
        const { message, data } = error as ErrorResponse;
        setIsLoading(false);
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        });
      }
    };


    const saveMooringInformationHandler = async () => {
      try {
        setIsLoading(true)
        const payload = {
          inspectionoccurrencetime: formData?.inspectionoccurrencetime,
        }
  
        const response = await SaveMooringInformation(payload).unwrap()
        const { status, message } = response as CustomerResponse
        if (status === 200 || status === 201) {
          setSuccessMessage(message);  // ✅ Set success message
          setShowSuccessPopup(true);   // ✅ Show popup
          setIsLoading(false);
    
          // ✅ Auto-close after 3 seconds
          setTimeout(() => setShowSuccessPopup(false), 3000);
        } else  
          {
          setIsLoading(false)
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          })
        } // } else {
        //   toastRef?.current?.show({
        //     severity: 'error',
        //     summary: 'Error',
        //     detail: message,
        //     life: 3000,
        //   })
        //   setIsLoading(false)
        // }
      } catch (error) {
        const { message, data } = error as ErrorResponse
        setIsLoading(false)
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        })
      }
    }


    const saveBillingInformationHandler = async () => {
      try {
        setIsLoading(true)
       
        // Ensure selectedOption is processed before forming the payload
        handleSelection(selectedOption);

        const payload = {
            xero: selectedOption === "Xero" ? "true" : "false",
            quickbooks: selectedOption === "QuickBooks" ? "true" : "false",
        };

      //   const payload = {
      //     xero: selectedOption === "Xero" ? "Xero" : "",
      //     quickbook: selectedOption === "QuickBooks" ? "QuickBooks" : "",
      // };
  
        const response = await SaveBillingInformation(payload).unwrap()
        const { status, message } = response as CustomerResponse
        if (status === 200 || status === 201) {
          setSuccessMessage(message);  // ✅ Set success message
          setShowSuccessPopup(true);   // ✅ Show popup
          setIsLoading(false);
    
          setTimeout(() => setShowSuccessPopup(false), 3000);
        } else  {
          setIsLoading(false)
          toastRef?.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
            life: 3000,
          })
        }
      } catch (error) {
        const { message, data } = error as ErrorResponse
        setIsLoading(false)
        toastRef?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message || data?.message,
          life: 3000,
        })
      }
    }





    useEffect(() => {
      const fetchData = async () => {
        try {
          setIsFetching(true);
          const customerOwnerId = 2; // Replace with actual customerOwnerId
    
          // Fetch Email Settings
          const emailResponse = (await getEmailSettings(customerOwnerId).unwrap()) as EmailSettingsResponse;
          const emailSettings = emailResponse?.content?.[0] || {};

          setGetidforupdate(emailSettings?.id); // Corrected assignment

          // Check if values exist and set `emailSettingsEdit`
          if (Object.keys(emailSettings).length > 0) {
            setEmailSettingsEdit(true);
          }
    
          
          // Fetch Mooring Information
          const mooringResponse = (await getMooringInformation(customerOwnerId).unwrap()) as MooringInformationResponce;
          const mooringinfo = mooringResponse?.content?.[0] || {};

          setMooringidforupdate(mooringinfo?.id);

          // Check if values exist and set `emailSettingsEdit`
          if (Object.keys(mooringinfo).length > 0) {
            setMooringINformationEdit(true);
          }

           // Fetch Billing Information
           const billingResponse = (await getBillingInformation(customerOwnerId).unwrap()) as BillingInformationResponce;
           const Billinginfo = billingResponse?.content?.[0] || {};
 
           setBillingidforupdate(Billinginfo?.id);


                // Set radio button selection based on the fetched values
          if (Billinginfo?.xero === true) {
            setSelectedOption("Xero");
          } else if (Billinginfo?.quickbooks === true) {
            setSelectedOption("QuickBooks");
          } else {
            setSelectedOption(""); // No selection if both are false
          }
 
           // Check if values exist and set `emailSettingsEdit`
           if (Object.keys(Billinginfo).length > 0) {
            setBillingInformationEdit(true);
           }


          // Fetch Company Information
          const companyResponse = (await getCompanyInformation(customerOwnerId).unwrap()) as CompnayInformationResponce;
          const companyInfo = companyResponse?.content?.[0] || {};

          setCompanyidforupdate(companyInfo?.id);

          // Check if values exist and set `emailSettingsEdit`
          if (Object.keys(companyInfo).length > 0) {
            setCompanyInformationEdit(true);
          }
    
          // Update state using functional update to avoid stale state issues
          setFormData((prev) => ({
            ...prev,
            replyEmailAddress: emailSettings.replyToEmailAddress || '',
            overrideCustomerEmails: emailSettings.overrideCustomerEmail || false,
            overrideEmailAddress: emailSettings.overrideEmailAddress || '',
            remindertimeinDays: emailSettings.reminderTimeInDays?.toString() || '',
            enableEmailsfromtosend: emailSettings.enableEmails || false,
    
            companyname: companyInfo.companyName || '',
            companylogo: companyInfo.companyLogo || '',
            companyaddress: companyInfo.companyAddress || '',
            companyemailAddress: companyInfo.companyEmailAddress || '',

            inspectionoccurrencetime : mooringinfo.inspectionOccurrenceTime?.toString() || '' ,

          }));
    
          setIsFetching(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsFetching(false);
        }
      };
    
      fetchData();
    }, [selectedCustomerId]); // Runs when selectedCustomerId changes
    

 const handleToggle = (faq: SetStateAction<string>) => {
    if (faq === 'faq1' && accordion === 'faq1') {
      setAccordion('faq2')
    } else if (faq === 'faq2' && accordion === 'faq2') {
      setAccordion('faq1')
    } else {
      setAccordion(faq)
    }
  }

  const onPageChange = (event: any) => {
    setPageNumber(event.page)
    setPageNumber1(event.first)
    setPageSize(event.rows)
  }
  const columnStyle = {
    borderBottom: '1px solid #D5E1EA',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontWeight: 700,
  }

  const tableColumnsPermission = useMemo(
    () => [
      {
        id: 'firstName',
        label: 'Customer Name',
        body: CustomerfirstLastName,
        style: columnStyle,
      },
      {
        id: 'quickbookCustomerResponseDto.id',
        label: 'QuickBook Customer Name',
        style: columnStyle,
        body: (rowData: {
          id: string
          label: string
          dropdownValue: string
          quickbookCustomerId: any
          quickbookCustomerResponseDto: any
        }) => (
          <>
            <Dropdown
              optionLabel="label"
              optionValue="quickbookCustomerId"
              value={
                dropdownValues[rowData.id] ||
                (rowData?.quickbookCustomerResponseDto?.quickbookCustomerFirstName &&
                  (rowData?.quickbookCustomerResponseDto?.quickbookCustomerFirstName === null
                    ? '-'
                    : rowData?.quickbookCustomerResponseDto?.quickbookCustomerFirstName) +
                    ' ' +
                    (rowData?.quickbookCustomerResponseDto?.quickbookCustomerLastName === null
                      ? '-'
                      : rowData?.quickbookCustomerResponseDto?.quickbookCustomerLastName))
              }
              options={quickBookCustomer?.map?.((option: any) => {
                return { ...option, value: option.quickbookCustomerId }
              })}
              disabled={
                (rowData?.quickbookCustomerResponseDto?.id || !!dropdownDisabled[rowData.id]) &&
                rowData?.id !== currentlyEditing
              }
              onChange={(e) => {
                setDropdownValues({
                  ...dropdownValues,
                  [rowData.id]: e.target.value,
                })
              }}
              editable
              dataKey="id"
              style={{
                height: '32px',
                border: '1px solid #D5E1EA',
                borderRadius: '0.50rem',
                fontSize: '0.8rem',
                width: '40%',
                textAlign: 'center',
              }}
            />
          </>
        ),
      },
      {
        id: 'Action',
        label: 'Action',
        style: columnStyle,
        body: (rowData: any) => (
          <span
            className={`cursor-pointer underline ${savedValues[rowData.id] ? 'black' : 'text-green-500'}`}
            onClick={() => {
              const dropdownValue = dropdownValues[rowData.id]
              const hasDropdownSelected =
                rowData?.quickbookCustomerResponseDto?.id || savedValues[rowData.id]
              if (rowData.id === currentlyEditing) {
                // UpdateMapCustomerToQuickBook(rowData)
                MapCustomerToQuickBook(rowData)
                setCurrentlyEditing(null)
              } else if (dropdownValue || hasDropdownSelected) {
                if (!(savedValues[rowData?.id] || rowData?.quickbookCustomerResponseDto?.id)) {
                  MapCustomerToQuickBook(rowData)
                } else {
                  setCurrentlyEditing(rowData.id)
                }
              }
            }}>
            {rowData.id === currentlyEditing
              ? 'Save'
              : rowData?.quickbookCustomerResponseDto?.id || savedValues[rowData.id]
                ? 'Edit'
                : 'Save'}
          </span>
        ),
      },
    ],
    [dropdownValues, savedValues, dropdownDisabled, quickBookCustomer, currentlyEditing],
  )

  const getCustomerData = useCallback(async () => {
    setIsLoading(true)
    try {
      let params: Params = {}
      if (pageNumber) {
        params.pageNumber = pageNumber
      }
      if (pageSize) {
        params.pageSize = pageSize
      }

      const response = await fetchCustomerData(params).unwrap();
      const { status, content, message, totalSize } = response as CustomerResponse
      if (status === 200 && Array.isArray(content)) {
        if (content?.length > 0) {
          setIsLoading(false)
          setCustomerData(content)
          setTotalRecords(totalSize)
        } else {
          setIsLoading(false)

          setCustomerData([])
          setTotalRecords(totalSize)
        }
      } else {
        setIsLoading(false)
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          life: 3000,
        })
      }
    } catch (error) {
      setIsLoading(false)
      const { message: msg } = error as ErrorResponse
      console.error('Error occurred while fetching customer data:', msg)
    }
  }, [getCustomer, selectedCustomerId, pageSize, pageNumber])

  const fetchDataAndUpdate = useCallback(async () => {
    const { quickBookCustomerData } = await getQuickBookCustomerData()
    if (quickBookCustomerData !== null) {
      const parsedData = quickBookCustomerData?.map((item: any) => ({
        label: item?.quickbookCustomerFirstName + ' ' + item?.quickbookCustomerLastName,
        id: item?.id,
        quickbookCustomerId: item?.quickbookCustomerId,
      }))
      setQuickBookCustomer(parsedData)
    }
  }, [])

  const MapCustomerToQuickBook = async (rowData: any) => {
    try {
      setIsLoading(true)
      const selectedValue: any = dropdownValues[rowData.id]
      const response = await mapCustomerToQuickBook({
        quickbookCustomerId: selectedValue || rowData?.quickbookCustomerResponseDto?.id,
        customerId: rowData?.id,
      }).unwrap()
      const { status, message } = response as CustomerResponse
      if (status === 200 || status === 201) {
        setDropdownDisabled((prevState) => ({ ...prevState, [rowData.id]: true }))
        setSavedValues((prevState) => ({ ...prevState, [rowData.id]: dropdownValues[rowData.id] }))
        setIsLoading(false)
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: message,
          life: 3000,
        })
      } else {
        setIsLoading(false)
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          life: 3000,
        })
      }
    } catch (error) {
      const { message, data } = error as ErrorResponse
      setIsLoading(false)
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: message || data?.message,
        life: 3000,
      })
    }
  }


  // Handle button click to determine if we're saving or updating
  const handleEmailSettingsClick = () => {
    if (EmailSettingsEdit) {
      console.log('Updating Email Settings');
      updateEmailSettingsHandler();
    } else {
      console.log('Saving Email Settings');
      saveEmailSettingsHandler();
    }
  };

// Handle button click to determine if we're saving or updating
const handleCompanyInformationClick = () => {
  if (CompanyInformationEdit) {
    console.log('Updating Email Settings');
    updateCompanyInformationHandler();
  } else {
    console.log('Saving Email Settings');
    saveCompanyInformationHandler();
  }
};


// Handle button click to determine if we're saving or updating
const handleMooringInformationClick = () => {
  if (MooringInformationEdit) {
    console.log('Updating Email Settings');
    updateMooringInformationHandler();
  } else {
    console.log('Saving Email Settings');
    saveMooringInformationHandler();
  }
};


// Handle button click to determine if we're saving or updating
const handleBillingInformationClick = () => {
  
  if (BillingInformationEdit) {
    console.log('Updating Email Settings');
    updateBillingInformationHandler();
  } else {
    console.log('Saving Email Settings');
    saveBillingInformationHandler();
  }

};



 //   release the issues on prod.
  const UpdateMapCustomerToQuickBook = async (rowData: any) => {
    try {
      setIsLoading(true)
      const response = await editMapCustomerToQuickBook({
        customerId: rowData?.id,
        quickbookCustomerId: Object.values(dropdownValues)?.map((value: any) => value.id)?.[0],
      }).unwrap()
      const { status, message } = response as CustomerResponse
      if (status === 200 || status === 201) {
        setDropdownDisabled((prevState) => ({ ...prevState, [rowData.id]: true }))
        setSavedValues((prevState) => ({ ...prevState, [rowData.id]: dropdownValues[rowData.id] }))
        setIsLoading(false)
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: message,
          life: 3000,
        })
      } else {
        setIsLoading(false)
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          life: 3000,
        })
      }
    } catch (error) {
      const { message, data } = error as ErrorResponse
      setIsLoading(false)
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: data?.message,
        life: 3000,
      })
    }
  }

  useEffect(() => {
    fetchDataAndUpdate()
  }, [selectedCustomerId])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getCustomerData()
    }, 600)
    return () => clearTimeout(timeoutId)
  }, [selectedCustomerId, pageSize, pageNumber])

  return (
    <>
      <Toast ref={toast} />


        {/* ✅ Success Message Popup */}
    <Dialog
      header=""
      visible={showSuccessPopup}
      onHide={() => setShowSuccessPopup(false)}
      style={{ width: '400px' }}
      modal
    >
      <div className="p-4">
        <p className="text-lg text-green-600 font-bold text-center">
          {successMessage}
        </p>
      </div>
    </Dialog>

      <div style={{ height: '100vh' }}>
        <Header header="MOORMANAGE/Permission" />
        <div
          className={`flex gap-10 ml-6 mt-6`}
          style={{
            paddingRight: '40px',
            paddingLeft: '25px',
          }}>
          <div
            className="bg-[#FFFFFF] border-[1px] border-gray-300  rounded-lg"
            style={{
              flexGrow: 1,
              borderRadius: '10px',
            }}>
              {/* remove code for 3 */}
              {/* Email Settings start remove this code*/}
             <label
                style={{ backgroundColor: '#00426F', marginBottom: '2px'  }}
                htmlFor="faq1"
                className="cursor-pointer flex items-center justify-between h-14 rounded-md"
                onClick={() => handleToggle('faq1')}>
                <div className="flex items-center gap-4 ">
                  <div>
                    <h1 className="p-4 text-white text-xl font-extrabold">Email Settings</h1>
                  </div>
                </div>
                <div>
                  <div className="mr-2">
                    {accordion === 'faq1' ? (
                      <svg
                        width="24"
                        height="4"
                        viewBox="0 0 11 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.125 1.5C10.125 1.92188 9.77344 2.25 9.375 2.25H1.125C0.703125 2.25 0.375 1.92188 0.375 1.5C0.375 1.10156 0.703125 0.75 1.125 0.75H9.375C9.77344 0.75 10.125 1.10156 10.125 1.5Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <img
                        src="/assets/images/plus.png"
                        alt="Key Icon"
                        className="p-clickable"
                        style={{}}
                      />
                    )}
                  </div>
                </div>
              </label>
                {/* WorkOrderImages Data Table */}
                <div
                className={`content transition-all ease-in-out duration-500  ${accordion === 'faq1' ? '' : 'hidden'}`}>
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <div className="flex-grow bg-white rounded-md border">
                    <div
                      style={{
                        fontWeight: '700',
                        color: 'white',
                        padding: '14px',
                        fontSize: '15px',
                      }}>

                        <div className="flex gap-4 mt-4">
                            <p className="font-medium text-[#000000]">Enable Emails to send from MOORFIND</p>
                            <label className="custom-checkbox-container">
                              <input
                                type="checkbox"
                                id="enableEmailsfromtosend"
                                onChange={(e) => setFormData({ ...formData, enableEmailsfromtosend: e.target.checked })}
                                checked={formData.enableEmailsfromtosend}
                                className="custom-checkbox-input"
                                style={{
                                  border: '1px solid #D5E1EA',
                                  height: '22px',
                                  width: '22px',
                                  borderRadius: '5px',
                                }}
                              />
                              <span className="custom-checkbox"></span>
                            </label>
                          </div>

                      <div
                              id="DivAfterEnableEmails"
                              style={{
                                display: formData.enableEmailsfromtosend ? 'block' : 'none',
                                marginTop: '20px',
                              }}
                            > 


                     {/* Reply-to Email Address: */}
                          <div className="mt-6">
                            <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">Reply-to Email Address: 
                                <label className="custom-checkbox-container ml-2">
                                <input
                                type="text"
                                value={formData?.replyEmailAddress}
                                onChange={(e) => handleInputChange('replyEmailAddress', e.target.value)}
                                style={{
                                  width: '230px',
                                  height: '32px',
                                  border: '1px solid #D5E1EA',
                                  borderRadius: '0.50rem',
                                  fontSize: '0.8rem',
                                  paddingLeft: '0.5rem',
                                }}
                              />
                                </label>
                              </div>
                            </span>
                          </div>

                          <div className="flex gap-4 mt-4">

                        {/* Override Customer Email */}
                        <p className="font-medium text-[#000000] ">Override Customer Email</p>

                              <label className="custom-checkbox-container">
                                <input
                                  type="checkbox"
                                  id="overrideCustomerEmails"
                                  onChange={(e) => setFormData({ ...formData, overrideCustomerEmails: e.target.checked })}
                                  checked={formData.overrideCustomerEmails}
                                  className="custom-checkbox-input"
                                  style={{
                                    border: '1px solid #D5E1EA',
                                    height: '22px',
                                    width: '22px',
                                    borderRadius: '5px',
                                  }}
                                />
                                <span className="custom-checkbox"></span>
                              </label>
                              </div>

                             {/* Override Email Address:  */}
                          <div className="mt-6">
                            <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">Override Email Address: 
                                
                                <input
                                type="text"
                                value={formData?.overrideEmailAddress}
                                onChange={(e) => handleInputChange('overrideEmailAddress', e.target.value)}
                                style={{
                                  width: '230px',
                                  height: '32px',
                                  border: '1px solid #D5E1EA',
                                  borderRadius: '0.50rem',
                                  fontSize: '0.8rem',
                                  paddingLeft: '0.5rem',
                                }}
                              />
                              
                              </div>
                            </span>
                          </div>

                         {/* below   */}
                          <div className="flex gap-4 mt-4"></div> 

                          {/* Reminder Time (In Days): */}
                          <div className="mt-6">
                            <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">
                                Reminder Time (In Days):  
                                <label className="custom-checkbox-container ml-2">
                                  <input
                                    type="text"
                                    value={formData?.remindertimeinDays}
                                    onChange={(e) => {
                                      const numericValue = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                      handleInputChange('remindertimeinDays', numericValue);
                                    }}
                                    style={{
                                      width: '230px',
                                      height: '32px',
                                      border: '1px solid #D5E1EA',
                                      borderRadius: '0.50rem',
                                      fontSize: '0.8rem',
                                      paddingLeft: '0.5rem',
                                    }}
                                  />
                                </label>
                              </div>
                            </span>
                          </div>
                          {/* Above */}

                          <div className="flex gap-4 mt-4"></div> 
                     
                        <div
                          className={`"flex gap-6 bottom-2 left-6" ${isLoading ? 'blurred' : ''}`}
                            style={{
                              width: '100%',
                              height: '80px',
                              // backgroundColor: 'white',
                              padding: '0 12px',
                              bottom: '0px',
                            }}>
                            <Button
                               onClick={handleEmailSettingsClick}
                               label={EmailSettingsEdit ? 'Update' : 'Save'}
                              style={{
                                width: '89px',
                                height: '42px',
                                backgroundColor: '#0098FF',
                                cursor: 'pointer',
                                fontWeight: 'bolder',
                                fontSize: '1rem',
                                boxShadow: 'none',
                                color: 'white',
                                borderRadius: '0.50rem',
                                marginTop: '10px',
                              }}
                            />
                           
                          </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
{/* Email Settings End */}
{/* Company Information start */}
              <label
                  style={{ backgroundColor: '#00426F', marginBottom: '2px' }}
                  htmlFor="faq2"
                  className="cursor-pointer flex items-center justify-between h-14 rounded-md"
                  onClick={() => handleToggle('faq2')}>
                  <div className="flex items-center gap-4 ">
                    <div>
                      <h1 className="p-4 text-white text-xl font-extrabold">Company Information</h1>
                    </div>
                  </div>
                  <div>
                    <div className="mr-2">
                      {accordion === 'faq2' ? (
                        <svg
                          width="24"
                          height="4"
                          viewBox="0 0 11 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.125 1.5C10.125 1.92188 9.77344 2.25 9.375 2.25H1.125C0.703125 2.25 0.375 1.92188 0.375 1.5C0.375 1.10156 0.703125 0.75 1.125 0.75H9.375C9.77344 0.75 10.125 1.10156 10.125 1.5Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <img
                          src="/assets/images/plus.png"
                          alt="Key Icon"
                          className="p-clickable"
                          style={{}}
                        />
                      )}
                    </div>
                  </div>
              </label>
                {/* Company Information */}
                <div
                  className={`content transition-all ease-in-out duration-500  ${accordion === 'faq2' ? '' : 'hidden'}`}>
                  <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <div className="flex-grow bg-white rounded-md border">
                      <div
                        style={{
                          fontWeight: '700',
                          color: 'white',
                          padding: '14px',
                          fontSize: '15px',
                        }}>
                        
                        <div className="flex gap-4 mt-4"></div> 

                              {/* Company Name:   */}
                              <div className="mt-6">
                            <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">Company Name:  
                                <label className="custom-checkbox-container ml-2">
                                <input
                                type="text"
                                value={formData?.companyname}
                                onChange={(e) => handleInputChange('companyname', e.target.value)}
                                style={{
                                  width: '230px',
                                  height: '32px',
                                  border: '1px solid #D5E1EA',
                                  borderRadius: '0.50rem',
                                  fontSize: '0.8rem',
                                  paddingLeft: '0.5rem',
                                }}
                              />
                                </label>
                              </div>
                            </span>
                          </div>


                          {/* Company Logo:   */}
                          {/* <div className="mt-6">
                            <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">Company Logo:  
                              <div className="flex gap-1 items-center">Upload  </div>
                              </div>
                            </span>
                          </div> */}

                        <div className="mt-6">
                          <span className="font-medium text-sm text-[#000000]">
                            <div className="flex gap-1 items-center">Company Logo:  


                                {/* Upload Button */}
                                <button
                                  onClick={() => document.getElementById('logoUploadInput')?.click()}
                                  style={{
                                    marginLeft: '10px',
                                    backgroundColor: '#0098FF',
                                    color: 'white',
                                    borderRadius: '0.50rem',
                                    padding: '5px 15px',
                                    fontSize: '0.8rem',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Upload
                                </button>

                                <label className="custom-checkbox-container ml-2" style={{ display: 'none' }}>
                                  <input
                                    type="text"
                                    value={formData?.companylogo}
                                    onChange={(e) => handleInputChange('companylogo', e.target.value)}
                                    style={{
                                      width: '230px',
                                      height: '32px',
                                      border: '1px solid #D5E1EA',
                                      borderRadius: '0.50rem',
                                      fontSize: '0.8rem',
                                      paddingLeft: '0.5rem',
                                    }}
                                    disabled // Disable manual editing since the logo will be selected through upload
                                  />
                                </label>


                            

                              {/* Hidden File Input */}
                              <input
                                id="logoUploadInput"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                  if (e.target.files && e.target.files[0]) {
                                    const file = e.target.files[0];
                                    const reader = new FileReader();

                                    // Preview the selected image
                                    reader.onload = () => {
                                      handleInputChange('companylogo', reader.result as string);
                                    };

                                    reader.readAsDataURL(file);
                                  }
                                }}
                              />
                            </div>

                            {/* Preview Selected Image */}
                            {formData.companylogo && (
                              <div className="mt-4" style={{ paddingLeft: '113px' }}>
                                <img
                                  src={formData.companylogo}
                                  alt="Company Logo Preview"
                                  style={{
                                    width: '100px',
                                    height: '100px',
                                    border: '1px solid #D5E1EA',
                                    borderRadius: '10px',
                                    objectFit: 'contain',
                                  }}
                                />
                              </div>
                            )}
                          </span>
                        </div>


                          {/* Company Address:   */}
                          <div className="mt-6">
                            <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">Company Address:  
                                <label className="custom-checkbox-container ml-2">
                                <input
                                type="text"
                                value={formData?.companyaddress}
                                onChange={(e) => handleInputChange('companyaddress', e.target.value)}
                                style={{
                                  width: '230px',
                                  height: '32px',
                                  border: '1px solid #D5E1EA',
                                  borderRadius: '0.50rem',
                                  fontSize: '0.8rem',
                                  paddingLeft: '0.5rem',
                                }}
                              />
                                </label>
                              </div>
                            </span>
                          </div>

                             {/* Company Email Address:   */}
                          <div className="mt-6">
                            <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">Company Email Address:  
                                <label className="custom-checkbox-container ml-2">
                                  <input
                                    type="text"
                                    value={formData?.companyemailAddress}
                                    onChange={(e) => {
                                      const email = e.target.value;
                                      handleInputChange('companyemailAddress', email);

                                      // Email validation regex
                                      const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
                                      const invalidSpecialCharRegex = /[@][^a-zA-Z0-9.]/; // Detects invalid special characters after @

                                      if (!emailRegex.test(email)) {
                                        setFormData((prev) => ({ ...prev, emailError: 'Please enter a valid email address.' }));
                                      } else if (invalidSpecialCharRegex.test(email)) {
                                        setFormData((prev) => ({ ...prev, emailError: 'Special characters are not allowed after @.' }));
                                      } else {
                                        setFormData((prev) => ({ ...prev, emailError: '' }));
                                      }
                                    }}
                                    style={{
                                      width: '230px',
                                      height: '32px',
                                      border: '1px solid #D5E1EA',
                                      borderRadius: '0.50rem',
                                      fontSize: '0.8rem',
                                      paddingLeft: '0.5rem',
                                    }}
                                  />
                                </label>
                              </div>
                              {formData?.emailError && (
                                <div className="text-red-500 text-xs mt-1">{formData.emailError}</div>
                              )}
                            </span>
                          </div>



                          <div
                          className={`"flex gap-6 bottom-2 left-6" ${isLoading ? 'blurred' : ''}`}
                            style={{
                              width: '100%',
                              height: '80px',
                              // backgroundColor: 'white',
                              padding: '0 12px',
                              bottom: '0px',
                            }}>
                            <Button
                               onClick={handleCompanyInformationClick}
                               label={CompanyInformationEdit ? 'Update' : 'Save'}
                              style={{
                                width: '89px',
                                height: '42px',
                                backgroundColor: '#0098FF',
                                cursor: 'pointer',
                                fontWeight: 'bolder',
                                fontSize: '1rem',
                                boxShadow: 'none',
                                color: 'white',
                                borderRadius: '0.50rem',
                                marginTop: '10px',
                              }}
                            />
                           
                          </div>


                      </div>
                    </div>
                  </div>  
                </div>
{/* Company Information End */}


{/* Mooring Information start */}
<label
                  style={{ backgroundColor: '#00426F', marginBottom: '2px' }}
                  htmlFor="faq3"
                  className="cursor-pointer flex items-center justify-between h-14 rounded-md"
                  onClick={() => handleToggle('faq3')}>
                  <div className="flex items-center gap-4 ">
                    <div>
                      <h1 className="p-4 text-white text-xl font-extrabold">Mooring Information</h1>
                    </div>
                  </div>
                  <div>
                    <div className="mr-2">
                      {accordion === 'faq3' ? (
                        <svg
                          width="24"
                          height="4"
                          viewBox="0 0 11 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.125 1.5C10.125 1.92188 9.77344 2.25 9.375 2.25H1.125C0.703125 2.25 0.375 1.92188 0.375 1.5C0.375 1.10156 0.703125 0.75 1.125 0.75H9.375C9.77344 0.75 10.125 1.10156 10.125 1.5Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <img
                          src="/assets/images/plus.png"
                          alt="Key Icon"
                          className="p-clickable"
                          style={{}}
                        />
                      )}
                    </div>
                  </div>
              </label>
                {/* Mooring Information */}
                <div
                  className={`content transition-all ease-in-out duration-500  ${accordion === 'faq3' ? '' : 'hidden'}`}>
                  <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <div className="flex-grow bg-white rounded-md border">
                      <div
                        style={{
                          fontWeight: '700',
                          color: 'white',
                          padding: '14px',
                          fontSize: '15px',
                        }}>
                        
                        <div className="flex gap-4 mt-4"></div> 

                              {/* Inspection Occurrence Time (In Years):    */}
                              <div className="mt-6">
                                <span className="font-medium text-sm text-[#000000]">
                              <div className="flex gap-1 items-center">Inspection Occurrence Time (In Years):   
                                <label className="custom-checkbox-container ml-2">
                                <input
                                type="text"
                                value={formData?.inspectionoccurrencetime}
                                    onChange={(e) => {
                                      const numericValue = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                      handleInputChange('inspectionoccurrencetime', numericValue);
                                    }}
                                style={{
                                  width: '230px',
                                  height: '32px',
                                  border: '1px solid #D5E1EA',
                                  borderRadius: '0.50rem',
                                  fontSize: '0.8rem',
                                  paddingLeft: '0.5rem',
                                }}
                              />
                                </label>
                              </div>
                            </span>
                          </div>

                          <div
                          className={`"flex gap-6 bottom-2 left-6" ${isLoading ? 'blurred' : ''}`}
                            style={{
                              width: '100%',
                              height: '80px',
                              // backgroundColor: 'white',
                              padding: '0 12px',
                              bottom: '0px',
                            }}>
                            <Button
                               onClick={handleMooringInformationClick}
                               label={MooringInformationEdit ? 'Update' : 'Save'}
                              style={{
                                width: '89px',
                                height: '42px',
                                backgroundColor: '#0098FF',
                                cursor: 'pointer',
                                fontWeight: 'bolder',
                                fontSize: '1rem',
                                boxShadow: 'none',
                                color: 'white',
                                borderRadius: '0.50rem',
                                marginTop: '10px',
                              }}
                            />
                           
                          </div>

                      
                      </div>
                    </div>
                  </div>  
                </div>
{/* Mooring Information End */}

{/* Billing Information Start */}



        <label
                          style={{ backgroundColor: '#00426F', marginBottom: '2px' }}
                          htmlFor="faq5"
                          className="cursor-pointer flex items-center justify-between h-14 rounded-md"
                          onClick={() => handleToggle('faq5')}>
                          <div className="flex items-center gap-4 ">
                            <div>
                              <h1 className="p-4 text-white text-xl font-extrabold">Billing Information</h1>
                            </div>
                          </div>
                          <div>
                            <div className="mr-2">
                              {accordion === 'faq5' ? (
                                <svg
                                  width="24"
                                  height="4"
                                  viewBox="0 0 11 3"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M10.125 1.5C10.125 1.92188 9.77344 2.25 9.375 2.25H1.125C0.703125 2.25 0.375 1.92188 0.375 1.5C0.375 1.10156 0.703125 0.75 1.125 0.75H9.375C9.77344 0.75 10.125 1.10156 10.125 1.5Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <img
                                  src="/assets/images/plus.png"
                                  alt="Key Icon"
                                  className="p-clickable"
                                  style={{}}
                                />
                              )}
                            </div>
                          </div>
                      </label>
                        {/* Billing Information */}
                        <div className={`content transition-all ease-in-out duration-500 ${accordion === 'faq5' ? '' : 'hidden'}`}>
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className="flex-grow bg-white rounded-md border">
              <div
                style={{
                  fontWeight: '700',
                  color: 'white',
                  padding: '14px',
                  fontSize: '15px',
                }}
              >
                <div className="mt-6">
                  <span className="font-medium text-sm text-[#000000]">
                  <div className="flex gap-6 items-center" style={{ paddingLeft: '20px' }}>
                      {/* Xero First, then Radio Button */}
                      {/* Xero First, then Radio Button (Reduced Space) */}
                      <label className="flex items-center gap-1" style={{ fontSize: '19px' }}>
                        Xero
                        <input
                          type="radio"
                          name="BillingOption"
                          value="Xero"
                          checked={selectedOption === "Xero"}
                          onChange={() => handleSelection("Xero")}
                          className="ml-0"
                          style={{ transform: "scale(1.3)", marginLeft: "-112px" , marginTop: "5px", }} /* Adjusted for closer spacing */
                        />
                      </label>


                      {/* QuickBooks First, then Radio Button */}
                      <label className="flex items-center gap-1" style={{ fontSize: '19px' }}>
                        QuickBooks
                        <input
                          type="radio"
                          name="BillingOption"
                          value="QuickBooks"
                          checked={selectedOption === "QuickBooks"}
                          onChange={() => handleSelection("QuickBooks")}
                          className="ml-0"
                          style={{ transform: "scale(1.3)", marginLeft: "-112px" , marginTop: "5px", }} /* Adjusted for closer spacing */
                        />
                      </label>
                    </div>
                  </span>
                </div>

                <div
                  className={`flex gap-6 bottom-2 left-6 ${isLoading ? 'blurred' : ''}`}
                  style={{
                    width: '100%',
                    height: '80px',
                    padding: '0 12px',
                    bottom: '0px',
                  }}
                >
                  <Button
                    onClick={handleBillingInformationClick}
                    label={BillingInformationEdit ? 'Update' : 'Save'}
                    style={{
                      width: '89px',
                      height: '42px',
                      backgroundColor: '#0098FF',
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                      fontSize: '1rem',
                      boxShadow: 'none',
                      color: 'white',
                      borderRadius: '0.50rem',
                      marginTop: '30px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>





{/* Billing Information End */}

            {/* <div className="text-md font-semibold rounded-t-lg bg-[#00426F]" style={{ marginBottom: '1px' }}>
              <h1 className="p-4 text-xl font-extrabold  text-white">Company Information</h1>
            </div> */}
{/* Customer Mapping start */}
              <label
                  style={{ backgroundColor: '#00426F', marginBottom: '2px' }}
                  htmlFor="faq4"
                  className="cursor-pointer flex items-center justify-between h-14 rounded-md"
                  onClick={() => handleToggle('faq4')}>
                  <div className="flex items-center gap-4 ">
                  <div className="text-md font-semibold rounded-t-lg">
                      <h1 className="p-4 text-xl font-extrabold  text-white">{properties.Settings}</h1>
                  </div>
                  </div>
                  <div>
                    <div className="mr-2">
                      {accordion === 'faq4' ? (
                        <svg
                          width="24"
                          height="4"
                          viewBox="0 0 11 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.125 1.5C10.125 1.92188 9.77344 2.25 9.375 2.25H1.125C0.703125 2.25 0.375 1.92188 0.375 1.5C0.375 1.10156 0.703125 0.75 1.125 0.75H9.375C9.77344 0.75 10.125 1.10156 10.125 1.5Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <img
                          src="/assets/images/plus.png"
                          alt="Key Icon"
                          className="p-clickable"
                          style={{}}
                        />
                      )}
                    </div>
                  </div>
              </label>
                {/* Company Information */}
                <div
                  className={`content transition-all ease-in-out duration-500  ${accordion === 'faq4' ? '' : 'hidden'}`}>
                  <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <div className="flex-grow bg-white rounded-md border">
                      <div
                        style={{
                          fontWeight: '700',
                          color: 'white',
                          padding: '14px',
                          fontSize: '15px',
                        }}>
                        
                        <div
                            data-testid="customer-admin-data"
                            className="flex flex-col"
                            style={{ height: 'calc(100vh - 200px)' }}>
                            <div className="flex-grow relative overflow-auto">
                              <DataTableComponent
                                tableStyle={{
                                  fontSize: '12px',
                                  color: '#000000',
                                  fontWeight: 600,
                                  backgroundColor: '#D9D9D9',
                                  borderRadius: '0 0 10px 10px',
                                  overflow: 'auto',
                                }}
                                scrollable={true}
                                data={customerData}
                                columns={tableColumnsPermission}
                                style={{ borderBottom: '1px solid #D5E1EA', fontWeight: '500' }}
                                emptyMessage={
                                  <div className="text-center mt-14">
                                    <img
                                      src="/assets/images/empty.png"
                                      alt="Empty Data"
                                      className="w-20 mx-auto mb-4"
                                    />
                                    <p className="text-gray-500">{properties.noDataMessage}</p>
                                    {isLoading && (
                                      <ProgressSpinner
                                        style={{
                                          position: 'absolute',
                                          top: '80%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          width: '50px',
                                          height: '50px',
                                        }}
                                        strokeWidth="4"
                                      />
                                    )}
                                  </div>
                                }
                              />
                            </div>
                            <div className="mt-auto">
                              <Paginator
                                first={pageNumber1}
                                rows={pageSize}
                                totalRecords={totalRecords}
                                rowsPerPageOptions={[5, 10, 20, 30]}
                                onPageChange={onPageChange}
                                style={{
                                  position: 'sticky',
                                  bottom: 0,
                                  zIndex: 1,
                                  backgroundColor: 'white',
                                  borderTop: '1px solid #D5E1EA',
                                  padding: '0.5rem',
                                }}
                              />
                            </div>
                          </div>

                      </div>
                    </div>
                  </div>  
                </div>

{/* Customer Mapping start */}
{/* data  */}

            {/* <div className="text-md font-semibold rounded-t-lg bg-[#00426F]">
              <h1 className="p-4 text-xl font-extrabold  text-white">{properties.Settings}</h1>
            </div>
             */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
